import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";


const ResetButton = ({ query, resetQuery }) => {
    const { t } = useTranslation();
    if (!query) return null;
    return (
        <button className="reset-input-button" type="reset" onClick={resetQuery} aria-label={t("Clear input")}>
            <img src="/static/img/ic_delete.svg" alt={t("Clear input")} />
        </button>
    );
};

ResetButton.propTypes = {
    query: PropTypes.string.isRequired || PropTypes.object.isRequired,
    resetQuery: PropTypes.func.isRequired,
};

export default ResetButton;
