export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '40px',
        paddingLeft: '74px',
        background: 'initial',
        border: 'initial',
        boxShadow: 'initial',
        '&:hover': {
            border: 'initial',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 29,
        borderRadius: '4px',
        marginTop: '16px',
        boxShadow: 'initial',
        border: '1px solid #d9e0e9',
    }),
    menuList: (provided) => ({
        ...provided,
        padding: '25px 29px',
        maxHeight: '285px', // Set max height here
        overflowY: 'auto', // Allow vertical scrolling
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        fontFamily: 'Moderat, sans-serif',
        fontWeight: state.isFocused ? '700' : '400', // state.isSelected
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'initial',
        color: '#004071',
        marginBottom: '20px',
        transition: 'all .15s linear',
        backgroundColor: 'initial',
        padding: 'initial',
        '&:active': {
            backgroundColor: 'initial',
        },
        '&:hover': {
            fontWeight: '700',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        fontFamily: 'Moderat, sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'initial',
        color: '#004071',
        maxWidth: '240px',
        overflow: 'hidden',
        '@media (max-width: 575px)': {
            maxWidth: '200px',
        },
        '@media (max-width: 375px)': {
            maxWidth: '150px',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#9bb4c6',
        fontSize: '16px',
        margin: '0',
        '@media (max-width: 575px)': {
            fontSize: '13px',
            lineHeight: '20px'
        }
    }),
    input: (provided) => ({
        ...provided,
        margin: '0',
        padding: '0',
        color: '#004071',
        fontSize: '16px',
        lineHeight: '24px',
        maxWidth: '240px',
        overflow: 'hidden',
        '@media (max-width: 575px)': {
            maxWidth: '200px',
        },
        '@media (max-width: 375px)': {
            maxWidth: '150px',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '0',
        cursor: 'pointer',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '0',
        cursor: 'pointer',
        color: state.isFocused ? '#4A90E2' : '#a3a3a3',
        '&:hover': {
            color: '#4A90E2',
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#E0E0E0',
        borderRadius: '2px',
        display: 'flex',
        margin: '2px',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#333',
        fontSize: '14px',
        padding: '2px',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#666',
        fontSize: '14px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#999',
            color: 'white',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: '0 8px',
    }),
    group: (provided) => ({
        ...provided,
        padding: '5px 10px',
    }),
    groupHeading: (provided) => ({
        ...provided,
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
        padding: '5px 10px',
    }),
};